import { redirect } from "react-router";
import poster from "~/images/poster.jpg";
import { getUserId } from "~/session.server";
import type { Route } from "./+types/index";

export const loader = async ({ request }: Route.LoaderArgs) => {
  const userId = await getUserId(request);
  if (userId) {
    return redirect("/projects");
  }
  return null;
};

export default function Index() {
  return (
    <div className="ca">
      <h2>5 Minute Intro</h2>
      <div id="video" className="img-thumbnail ca">
        <video
          className="img-responsive"
          id="movie"
          controls
          preload="none"
          poster={poster}
          onClick={(e) => {
            const vid = e.target as HTMLVideoElement;
            if (vid.paused || vid.ended) {
              vid.play();
            } else {
              vid.pause();
            }
            e.preventDefault();
          }}
          src="https://pfcs-misc.s3.amazonaws.com/2016-01-08_Client_Access.mp4"
        />
      </div>
      <p className="my-4">
        Watch a quick overview of signing up for and using Client Access
      </p>
      <a
        href="https://www.petefowler.com/contactus"
        target="_blank"
        rel="noreferrer"
        className="text-uppercase mt-4 inline-block bg-red-600 px-12 py-6 text-4xl font-normal leading-normal text-white transition duration-500 ease-in-out hover:bg-red-500 hover:text-white hover:no-underline focus:text-white focus:no-underline"
        style={{
          fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif'
        }}
      >
        Contact Us for a Free Consultation
      </a>
    </div>
  );
}
